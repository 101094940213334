

























import BaseSheet from "@/components/shared/sheet/BaseSheet.vue";
import StudieplanDetailsPage from "@/pages/studieplan/StudieplanDetailsPage.vue";
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  name: "CreateExecutionPlanPrerequisitesPage",
  components: { BaseSheet, StudieplanDetailsPage },
});
